import request from "superagent";

export const LOAD_VIDEOS = "THE_WIRE/LOAD_VIDEOS";
export const RESET_VIDEOS = "THE_WIRE/RESET_VIDEOS";
export const SET_DISPLAY_FILTER = "THE_WIRE/SET_DISPLAY_FILTER";
export const SET_DISPLAY_MODE = "THE_WIRE/SET_DISPLAY_MODE";
export const SET_OFFSET = "THE_WIRE/SET_OFFSET";
export const SET_TRENDING = "THE_WIRE/SET_TRENDING";
export const SET_FETCHING = "THE_WIRE/SET_FETCHING";
export const SET_ACQUISITIONS = "THE_WIRE/SET_ACQUISITIONS";
export const SET_DAY_FILTER = "THE_WIRE/SET_DAY_FILTER";
export const SET_NO_MORE = "THE_WIRE/SET_NO_MORE";
export const SET_HAS_NEW_VIDEO = "THE_WIRE/SET_HAS_NEW_VIDEO";

export function setDisplayFilter(filter) {
  return {
    type: SET_DISPLAY_FILTER,
    payload: filter
  };
}

export function setDisplayMode(mode) {
  return {
    type: SET_DISPLAY_MODE,
    payload: mode
  };
}

export function setDayFilter(filter) {
  return {
    type: SET_DAY_FILTER,
    payload: filter
  };
}

export function setNoMore(status) {
  return {
    type: SET_NO_MORE,
    payload: status
  };
}

export function setFetching(status) {
  return {
    type: SET_FETCHING,
    payload: status
  };
}

export function setOffset(offset) {
  return {
    type: SET_OFFSET,
    payload: offset
  };
}

export function setTrending(trending) {
  return {
    type: SET_TRENDING,
    payload: trending
  };
}

export function setHasNewVideo() {
  return {
    type: SET_HAS_NEW_VIDEO,
    payload: false
  };
}

export function resetVideos() {
  return {
    type: RESET_VIDEOS
  };
}

export function fetchVideos() {
  return async (dispatch, getState) => {
    const { offset, acquisitions, trending, dayFilter } = getState().thewire;

    if (trending) {
      await getVideos(dispatch, getState, {
        offset,
        max: 12,
        sort: "latest",
        theWire: true,
        trending: true
      });
    } else if (dayFilter !== "") {
      await getVideos(dispatch, getState, {
        offset: 0,
        max: acquisitions[dayFilter],
        sort: "latest",
        theWire: true,
        trending: false
      });
    } else {
      await getVideos(dispatch, getState, {
        offset,
        max: 12,
        sort: "latest",
        theWire: true,
        trending: false
      });
    }
  };
}

export function fetchAcquisitions() {
  return async (dispatch, getState) => {
    request.get(`/api/public/videos/theWireAcquisitions`).then((response) => {
      dispatch({
        type: SET_ACQUISITIONS,
        payload: response.body
      });
    });
  };
}

export function checkNewVideo() {
  return async (dispatch, getState) => {
    const { trending, videos } = getState().thewire;
    const time = new Date().getTime();
    request
      .get(`/api/public/videos?_=${time}`)
      .query({
        offset: 0,
        max: 1,
        sort: "latest",
        theWire: true,
        trending: trending
      })
      .then((response) => {
        if (videos[0] && response.body.result[0].jmId !== videos[0].jmId) {
          dispatch({
            type: SET_HAS_NEW_VIDEO,
            payload: true
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

function getVideos(dispatch, getState, params) {
  const { fetching, offset } = getState().thewire;
  if (!fetching) {
    dispatch(setFetching(true));
    const time = new Date().getTime();
    request
      .get(`/api/public/videos?_=${time}`)
      .query(params)
      .then((response) => {
        dispatch({
          type: LOAD_VIDEOS,
          payload: response.body.result
        });
        dispatch(setOffset(offset + 12));
        dispatch(setFetching(false));
      });
  }
}
