import algoliasearch from "algoliasearch";

export const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

export const mainIndex = client.initIndex(process.env.REACT_APP_ALGOLIA_MAIN_INDEX);
export const durationDescIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_DURATION_DESC_INDEX
);
export const durationAscIndex = client.initIndex(process.env.REACT_APP_ALGOLIA_DURATION_ASC_INDEX);
export const originalPublishDateDescIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_ORIGINAL_PUBLISHING_DESC_INDEX
);
export const originalPublishDateAscIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_ORIGINAL_PUBLISHING_ASC_INDEX
);
export const signedDateDescIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_SIGNED_DATE_DESC_INDEX
);
export const signedDateAscIndex = client.initIndex(
  process.env.REACT_APP_ALGOLIA_SIGNED_DATE_ASC_INDEX
);

export const querySuggestion = client.initIndex(
  process.env.REACT_APP_ALGOLIA_QUERY_SUGGESTION_INDEX
);
