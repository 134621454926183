import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { sortBy } from "lodash";
import { Button, Dialog, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import ModalListItemMultipleVideos from "./ModalListItemMultipleVideos";
import { Display } from "../../helpers/index";
import CreateList from "./CreateList";
import { Mixpanel } from "./Mixpanel";
import Analytics from "../../helpers/analytics";
import AddToListModalWrapper from "./styles/AddToListModal.style";
import { useDispatch } from "react-redux";
import fetchPlaylist from "redux/actions/playlist/services/fetchPlaylist";

function AddToListModalMultipleVideos(props) {
  const createListComponent = useRef();
  const {
    auth,
    user,
    resetVideoListSavedState,
    createVideoList,
    getListsByVideo,
    addMultiplesVideosToList,
    downloadAllVideosPlaylists,
    playlists,
    toggleMultipleVideosModal
  } = props;

  const [state, setState] = useState({
    filter: "",
    filteredItems: []
  });

  const currentPlaylist = playlists?.playlistInfo?.uuid;
  const dispatch = useDispatch();

  const [selectedFilter, setSelectedFilter] = useState(
    user.popupLists && user.popupLists.length > 0 ? "recent" : null
  );

  const path =
    window.location.pathname.includes("/login") || window.location.pathname.includes("/register");

  const onAfterOpen = () => {
    user.selectedVideos?.map((item) => getListsByVideo(item, null, null, true));
    setSelectedFilter("recent");
  };

  const closeModal = () => {
    toggleMultipleVideosModal(null, "", false);
    localStorage.removeItem("addTolist");
    getListsByVideo(user.selectedVideo, null, null, true);
    setState({ filter: "", filteredItems: [] });
    setSelectedFilter(user.popupLists?.length > 0 ? "recent" : null);
    if (createListComponent.current) {
      createListComponent.current.resetErrorState();
    }
  };

  const onListChanged = (item) => {
    // disable multiple click
    if (item.disabled) {
      return;
    }

    if (!item.disableCheckBox) {
      Mixpanel.track("Added Video to Playlist", {
        JVID: user.selectedVideos,
        PlaylistUUID: item.uuid
      });
      Analytics.track("Added Video to Playlist", {
        category: "LP-Playlist",
        label: item.uuid
      });
      addMultiplesVideosToList(
        item.uuid,
        user.selectedVideos,
        auth.user.access_token,
        user.selectedVideos
      );
    }
  };

  const changeFilter = (event) => filterLists(event.target.value, user.popupLists);

  const filterLists = (filter, lists) => {
    const maxCount = 30;
    const skipFilter = !filter || filter.length < 3;
    const filteredLists = [];
    let newList = [];

    if (selectedFilter === "recent") {
      newList = sortBy(lists || [], ["containsVideo"]).reverse();
    }
    if (selectedFilter === "az") {
      newList = sortBy(lists || [], [(list) => list.name.toLowerCase(), "name"]);
    }

    if (selectedFilter === "za") {
      newList = sortBy(lists || [], [(list) => list.name.toLowerCase(), "name"]).reverse();
    }
    (newList || []).some((item) => {
      if (skipFilter || item.name.toLowerCase().includes(filter.toLowerCase())) {
        filteredLists.push(item);
      }
      return filteredLists.length >= maxCount;
    });
    setState({ filter: filter, filteredItems: filteredLists });
  };

  const onListCreated = (uuid) => {
    addMultiplesVideosToList(
      uuid,
      user.selectedVideos,
      auth.user.access_token,
      user.selectedVideos
    );
    user.selectedVideos.map((item) => getListsByVideo(item, null, null, true));
    dispatch(fetchPlaylist());
  };

  useEffect(() => {
    filterLists(state.filter, user.popupLists);
  }, [state.filter, user.popupLists, selectedFilter]);

  const items = state.filteredItems.map((item, index) => {
    if (item?.uuid === currentPlaylist) {
      return null;
    } else {
      return (
        <ModalListItemMultipleVideos
          list={item}
          key={index}
          resetSavedState={resetVideoListSavedState}
          changeHandler={onListChanged}
        />
      );
    }
  });

  const isOpen =
    auth.loggedIn &&
    !path &&
    (user.listsModalMultipleVideosIsOpen || localStorage.getItem("addTolist"));

  useEffect(() => {
    downloadAllVideosPlaylists();
  }, [isOpen]);

  return (
    <Dialog maxWidth="lg" open={isOpen || false} onEntering={onAfterOpen} onClose={closeModal}>
      <AddToListModalWrapper>
        <Display if={user.loadingPopupLists}>
          <div className="uil-ring-css" style={{ transform: "scale(0.6)" }}>
            <div />
          </div>
        </Display>

        <Display if={!user.loadingPopupLists}>
          <div className="header-list">
            <div className="search-input">
              <div className="wrapper">
                <TextField
                  style={{ width: "100%" }}
                  value={state.filter}
                  onChange={changeFilter}
                  placeholder="Search by playlist name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="seperator" />
            </div>
            <div className="playlists-container">
              <h5>Add to playlist</h5>
              <div className="filters-container">
                <Button
                  className={classnames({ active: selectedFilter === "recent" })}
                  variant="outlined"
                  onClick={() => setSelectedFilter("recent")}
                  disabled={user.popupLists && user.popupLists.length === 0}
                >
                  Recent
                </Button>
                <Button
                  className={classnames({ active: selectedFilter === "az" })}
                  variant="outlined"
                  onClick={() => setSelectedFilter("az")}
                  disabled={user.popupLists && user.popupLists.length === 0}
                >
                  A - Z
                </Button>
                <Button
                  className={classnames({ active: selectedFilter === "za" })}
                  variant="outlined"
                  onClick={() => setSelectedFilter("za")}
                  disabled={user.popupLists && user.popupLists.length === 0}
                >
                  Z - A
                </Button>
              </div>
              {user.popupLists && user.popupLists.length > 0 ? (
                <div>
                  {state.filteredItems.length > 0 ? (
                    <>
                      <div className="video-lists-list">{items}</div>
                    </>
                  ) : (
                    <div className="no-lists">
                      <p className="small">No lists were found.</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="no-lists">
                  <p className="small">
                    Get started by creating a playlist to add the video to. Enter the playlist name
                    in the field below & click “Create New”.
                  </p>
                  <PlaylistAddIcon size={40} />
                </div>
              )}
            </div>
            <div className="add-to-list-popup-create-list">
              <CreateList
                isEmpty={user.popupLists && user.popupLists.length === 0}
                createVideoList={createVideoList}
                successCallback={onListCreated}
                ref={createListComponent}
              />
            </div>
          </div>
        </Display>
      </AddToListModalWrapper>
    </Dialog>
  );
}

AddToListModalMultipleVideos.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,

  resetVideoListSavedState: PropTypes.func.isRequired,
  createVideoList: PropTypes.func.isRequired,
  getListsByVideo: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  removeVideoFromList: PropTypes.func.isRequired,
  addVideoToList: PropTypes.func.isRequired
};

export default AddToListModalMultipleVideos;
